.player-container {
  margin: auto;
  border: #ffffff;
  border-width: 0.75em;
  border-style: solid;
  border-radius: 0.75vw;
  max-width: 1200px;
}

.player-wrapper {
  height: auto;
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="player-container"] {
        margin: 2vh;
        border: #ffffff;
        border-width: 0.5em;
        border-style: solid;
        border-radius: 0.5vh;
    }
  }