* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

nav {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  min-height: 8vh;
  width: 100%;
}

.nav-links {
  display: flex;
  justify-content: space-around;
  /* padding: 4px 0 4px 0; */
  margin-top: 1rem;
  width: 100%;
}

.nav-links li {
  list-style-type: none;
}

.burger {
  display: none;
  cursor: pointer;
}

.burger div {
  width: 25px;
  height: 5px;
  background-color: black;
  margin: 5px;
}

@media screen and (max-width: 768px) {
  .body {
    overflow-x: hidden !important;
  }
  body {
    overflow-x: hidden;
  }

  body.modal-open {
    overflow-x: hidden !important;
    position: fixed;
  }
  body.viewport-lg {
    position: absolute;
    overflow-x: hidden;
  }

  .nav-links {
    position: absolute;
    right: 0px;
    height: 92vh;
    margin-top: 0px;
    top: 8vh;
    background-color: #f2f1f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    transform: translateX(100%);
    z-index: 999;
    transition: transform 0.5s ease-out;
  }

  /* may want to change this if you dont want the text to transistion in */
  .nav-links li {
    /* opacity: 0; */
  }

  .burger {
    display: block;
  }
}

.nav-active {
  transform: translate(0%);
}

.button {
  border-color: black;
  background-color: #FFFFFF;
  border-style: none;
  width: 110px;
  padding: 5% 10% 5% 10%;
  border-radius: 5%;
}
