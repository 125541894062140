.App {
  text-align: center;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
  font-family: 'Oswald', sans-serif;
}

html {
  scroll-behavior: smooth;
}

.title{
  margin: 0.25em 5vw;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.flex-container {
  padding: 0;
  margin: auto;
  list-style: none;
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="flex-container"] {
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-block;
    justify-content: space-around;
  }
}
