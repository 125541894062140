.container-element {
  background: #ffffff;
  /* width: 25vw;
  height: 32.5vw; */
  width: 300px;
  height: 400px;
  margin: 5px;
  border-radius: 1vw;
  position: relative;
}

.content {
  width: 95%;
  height: 95%;
  top: 2.5%;
  left: 2.5%;
  position: absolute;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
}

.video-thumbnail-container img {
  width: 100%;
  border-radius: 0.5vw;
  overflow: hidden;
}

.video-description {
  margin: 5% 2.5%;
  word-wrap: break-word;
  overflow: hidden;
  height: auto;
  max-height: 37.5%;
}
.video-description h3 {
  color: #db9703;
  font-size: 35%;
  text-align: start;
}

.video-description h1 {
  color: black;
  font-size: 50%;
  text-align: start;
}

.video-description h2 {
  color: grey;
  font-size: 40%;
  text-align: start;
  position: relative;
  margin-bottom: 0;
}

.video-description h2 .fadeaway {
  position: absolute;
  bottom: 0%;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 0 0 15% 0;
  background-image: linear-gradient(to bottom, transparent, #ffffff);
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="container-element"] {
    background: #ffffff;
    width: 40vh;
    height: 55vh;
    margin: 5px;
    border-radius: 1vh;
    position: relative;
  }
}
